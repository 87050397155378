<template>
  <div>
    <b-card>
      <b-card-title> {{ $t("Add Locations") }} </b-card-title>
      <div />
      <b-row class="d-flex align-items-center justify-content-between">
        <b-col
          md="12"
          class="mb-2"
        >
          <b-button
            v-b-modal.modal-xl
            size="sm"
            variant="info"
            @click="showModal()"
          >
            {{ $t("Add New Location") }}
          </b-button>
          <br>
        </b-col>

        <!-- start filter-->
        <b-col
          md="6"
          sm="4"
          class="my-1 mt-2"
        >
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{
              $t("Per page")
            }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          class="my-1 mt-2"
        >
          <b-form-group
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  {{ $t("clear") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="table-container"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            :busy="isLoading"
            :items="filteredSetups"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(partnerIdType)="data">
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ data.item.locationInfo.partnerIdType }}
              </b-badge>
            </template>

            <template
              v-if="isRtl"
              #cell(titleAr)="data"
            >
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ data.item.locationInfo.titleAr }}
              </b-badge>
            </template>

            <template
              v-if="!isRtl"
              #cell(titleEn)="data"
            >
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ data.item.locationInfo.titleEn }}
              </b-badge>
            </template>

            <template #cell(Setups)="data">
              <span>
                <b-button
                  size="sm"
                  variant="dark"
                  @click="showDetail(data.item.locationInfo)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
            </template>

            <template #cell(isPrivate)="data">
              <b-form-checkbox
                v-model="data.item.locationInfo.isPrivate"
                name="check-button"
                switch
                :disabled="!isAdmin"
                @change="updateLocationStatus(data.item.locationInfo, data.item.locationInfo.isPrivate)"
              >
                {{ locationStatus(data.item.locationInfo.isPrivate) }}
              </b-form-checkbox>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex w-100">
                <div style="padding: 2px">
                  <b-button
                    size="sm"
                    variant="dark"
                    @click="showlocationMaterial(data.item.locationInfo)"
                  >
                    <feather-icon icon="CameraIcon" />
                  </b-button>
                </div>
                <div style="padding: 2px">
                  <b-button
                    size="sm"
                    variant="warning"
                    :disabled="!isAdmin || data.item.locationInfo.screens.length == 0"
                    @click="showLocationMoveScreenModal(data.item)"
                  >
                    <feather-icon icon="MonitorIcon" />
                  </b-button>
                </div>
                <div style="padding: 2px">
                  <b-button
                    size="sm"
                    variant="danger"
                    :disabled="isLocationDeletable(data.item)"
                    :clickable="isLocationDeletable(data.item)"
                    @click="deleteLocation(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center">
                <b-spinner label="Loading..." />
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          />
        </b-col>

        <!---  end of datatable --->
      </b-row>
    </b-card>

    <b-modal
      id="addLocationModal"
      ref="addLocationModal"
      v-model="modalShow"
      size="lg"
      hide-footer
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <span @click="close()">
          <br>
        </span>
      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(submitData)">
          <b-row class="d-flex align-items-center justify-content-between">
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t("Username") }}</label>
              <validation-provider
                v-slot="validationContext"
                :name="$t('Username')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="example-input-22344"
                  v-model="username"
                  name="example-input-22344"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2234-live-feedback"
                  @change="selectUsername($event)"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t("Please select an option") }}
                  </option>
                  <option
                    v-for="option in activatePartners"
                    :key="option.username"
                    :value="option"
                  >
                    {{ option.username }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-2234-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('Location Id')"
                :rules="{ required: true, alpha_num: true, min: 4 }"
              >
                <label>{{ $t("Location Id") }}</label>
                <b-form-input
                  id="example-input-1"
                  v-model="shownItem.locationId"
                  name="example-input-1"
                  size="sm"
                  :placeholder="$t('Location Id')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('Title In Arabic')"
                :rules="{ required: true, alpha_num: true, min: 4 }"
              >
                <label>{{ $t("Title In Arabic") }}</label>
                <b-form-input
                  id="example-input-2"
                  v-model="shownItem.titleAr"
                  name="example-input-2"
                  size="sm"
                  :placeholder="$t('Title In Arabic')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('Title In English')"
                :rules="{ required: true, alpha_num: true, min: 4 }"
              >
                <label>{{ $t("Title In English") }}</label>
                <b-form-input
                  id="example-input-2"
                  v-model="shownItem.titleEn"
                  name="example-input-2"
                  size="sm"
                  :placeholder="$t('Title In English')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t("City") }}</label>
              <validation-provider
                v-slot="validationContext"
                :name="$t('City')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="example-input-223"
                  v-model="city"
                  name="example-input-223"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-223-live-feedback"
                  @change="changeCity(city)"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t("Please select an option") }}
                  </option>
                  <option
                    v-for="option in cities"
                    :key="option.id"
                    :value="option"
                  >
                    {{ option.id }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-22-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t("Neighborhood") }}</label>

              <validation-provider
                v-slot="validationContext"
                :name="$t('Neighborhood')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="example-input-222"
                  v-model="shownItem.neighborhood"
                  name="example-input-222"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-222-live-feedback"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t("Please select an option") }}
                  </option>
                  <option
                    v-for="option in neighborhoods"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.id }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-222-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t("Segments") }}</label>

              <validation-provider
                v-slot="validationContext"
                :name="$t('Segments')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="example-input-22"
                  v-model="segment"
                  name="example-input-22"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-22-live-feedback"
                  @change="changeSegments(segment)"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t("Please select an option") }}
                  </option>
                  <option
                    v-for="option in segments"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.id }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-22-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('latitude')"
                :rules="{ required: true, min: 5, double: true }"
              >
                <label>{{ $t("latitude") }}</label>
                <feather-icon
                  icon="MapPinIcon"
                  size="12"
                  class="text-primary ml-map"
                  @click="showModal2"
                />
                <b-form-input
                  id="example-input-6"
                  v-model="shownItem.latitude"
                  name="example-input-6"
                  size="sm"
                  readonly
                  :placeholder="$t('latitude')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-6-live-feedback"
                />

                <b-form-invalid-feedback id="input-6-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('longitude')"
                :rules="{ required: true, min: 5, double: true }"
              >
                <label>{{ $t("longitude") }}</label>
                <feather-icon
                  icon="MapPinIcon"
                  size="12"
                  class="text-primary ml-map"
                  @click="showModal2"
                />
                <b-form-input
                  id="example-input-7"
                  v-model="shownItem.longitude"
                  name="example-input-7"
                  size="sm"
                  readonly
                  :placeholder="$t('longitude')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-7-live-feedback"
                />

                <b-form-invalid-feedback id="input-7-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('Estimated Traffic')"
                :rules="{ required: true, min: 2, min_value: 10 }"
              >
                <label>{{ $t("Estimated Traffic") }}</label>
                <b-form-input
                  id="example-input-9"
                  v-model="shownItem.estimatedTraffic"
                  name="example-input-9"
                  size="sm"
                  :placeholder="$t('Estimated Traffic')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-9-live-feedback"
                />

                <b-form-invalid-feedback id="input-9-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <!-- <label>Default Price</label> -->

              <validation-provider
                v-slot="validationContext"
                :name="$t('Default Price')"
                :rules="{ required: true, min: 1, min_value: 1 }"
              >
                <label>{{ $t("Default Price") }}</label>
                <b-form-input
                  id="example-input-8"
                  v-model="shownItem.defaultPrice"
                  name="example-input-8"
                  size="sm"
                  :placeholder="$t('Default Price')"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-8-live-feedback"
                />

                <b-form-invalid-feedback id="input-8-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t("businessField") }}</label>

              <validation-provider
                v-slot="validationContext"
                :name="$t('businessField')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="example-input-222"
                  v-model="shownItem.businessField"
                  name="example-input-222"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-222-live-feedback"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t("Please select an option") }}
                  </option>
                  <option
                    v-for="option in segments"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ isRtl ? option.arName : option.enName }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-222-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t("genderOption") }}</label>

              <validation-provider
                v-slot="validationContext"
                :name="$t('genderOption')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="example-input-222"
                  v-model="shownItem.genderOption"
                  name="example-input-222"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-222-live-feedback"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t("Please select an option") }}
                  </option>
                  <option
                    v-for="option in genderOptions"
                    :key="option.name"
                    :value="option.name"
                  >
                    {{ $t(`${option.name}`) }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-222-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t("locationStatus") }}</label>
              <b-form-checkbox
                v-model="!shownItem.isPrivate"
                class="checkbox-contaienr"
                name="check-button"
                switch
              >
                <span>{{ locationStatus(shownItem.isPrivate) }}</span>
              </b-form-checkbox>
            </b-col>
            <hr>
            <!-- ** link to partners start -->
            <!-- link to partners end ** -->
          </b-row>
          <b-row>
            <b-col cols="12">
              <leafletMap
                :height="selectionMap.hieght"
                :zoom="selectionMap.zoom"
                :locations="selectionMap.selectedLocation"
                :reset-map-zoom-op="true"
                :geosearch-op="true"
                :marker="true"
                :tooltip="true"
                :popup="true"
                :locations-op="selectionMap.selectedLocation"
                :center="selectionMap.center"
                @map-click="onMapClick"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="4"
              sm="4"
              class="mb-2"
            >
              <b-button
                class="text-capitalize mt-1"
                :ripple.400="'rgba(113, 102, 240, 0.15)'"
                type="submit"
                variant="primary"
              >
                {{ $t("Add New Location") }}
              </b-button>
            </b-col>
            <b-col
              md="4"
              sm="4"
              class="mb-2"
            >
              <b-button
                class="mt-1"
                @click="modalShow = false"
              >
                {{ $t("close") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="moveScreenModal"
      ref="moveScreenModal"
      v-model="locationMoveScreenModal"
      size="md"
      hide-footer
      hide-header
      centered
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <span @click="close()">
          <br>
        </span>
      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(doMoveLocationScreens)">
          <div class="d-block">
            <b-row class="d-flex align-items-center justify-content-between">
              <b-col
                md="6"
                class="mb-2"
              >
                <label>{{ $t("ToLocation") }}</label>
                <validation-provider
                  v-slot="validationContext"
                  :name="$t('ToLocation')"
                  :rules="{ required: true }"
                >
                  <b-form-select
                    id="toLocationIdSelection"
                    v-model="toLocationId"
                    name="toLocationIdSelection"
                    size="md"
                    :state="getValidationState(validationContext)"
                    @change="selectToLocation($event)"
                  >
                    <option
                      selected
                      :value="null"
                    >
                      {{ $t("Please select an option") }}
                    </option>
                    <option
                      v-for="option in moveScreensInfo.filterLocations"
                      :key="option.locationId"
                      :value="option"
                    >
                      {{ option.locationId }}
                    </option>
                  </b-form-select>

                  <b-form-invalid-feedback id="input-2234-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            <!-- ** link to partners start -->
            <!-- link to partners end ** -->
            </b-row>
          </div>
          <b-col class="mb-2">
            <b-button
              class="mx-1"
              type="submit"
              variant="primary"
              :disabled="moveScreensInfo.fromLocationId === null || moveScreensInfo.toLocationId === null"
            >
              {{ $t("MoveLocationScreens") }}
            </b-button>
            <b-button @click="closeMoveScreenModal()">
              {{ $t("close") }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="tableDetails"
      ref="tableDetails"
      size="lg"
      hide-footer
      hide-header
      centered
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <span @click="close()">
          <br>
        </span>
      </template>
      <div
        v-if="tableDetailsInfo.length > 0"
        class="d-block"
      >
        <b-row
          v-if="tableDetailsInfo[0].locationInfo.defaultAssets"
          class="my-1"
        >
          <b-col cols="6">
            <p class="font-weight-bold">
              {{ $t('Hd Portrait') }}
            </p>
            <b-img
              thumbnail
              fluid
              :src="'https://adx-assets.fra1.cdn.digitaloceanspaces.com/' + tableDetailsInfo[0].locationInfo.locationId + '/' + tableDetailsInfo[0].locationInfo.defaultAssets.hdPortrait"
              alt="Portrait Image"
            />
          </b-col>
          <b-col cols="6">
            <p class="font-weight-bold">
              {{ $t('Hd Landscape') }}
            </p>
            <b-img
              thumbnail
              fluid
              :src="'https://adx-assets.fra1.cdn.digitaloceanspaces.com/' + tableDetailsInfo[0].locationInfo.locationId + '/' + tableDetailsInfo[0].locationInfo.defaultAssets.hdLandscape"
              alt="Landscape Image"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <leafletMap
              :height="'200px'"
              :zoom="15"
              :locations="tableDetailsInfo"
              :reset-map-zoom-op="true"
              :geosearch-op="true"
              :marker="true"
              :tooltip="true"
              :popup="true"
              :locations-op="tableDetailsInfo"
              :center="[tableDetailsInfo[0].locationInfo.locationCoordinates.latitude, tableDetailsInfo[0].locationInfo.locationCoordinates.longitude]"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      v-model="locationMaterialModal"
      size="lg"
      hide-footer
    >
      <template #modal-header="{ close }">
        <span> {{ $t("locationMaterial") }} </span>
      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(submitLocationMaterial)">
          <div class="d-block">
            <b-row>
              <b-col
                md="6"
                sm="12"
                class="mb-2"
              >
                <div
                  id="profile-pic-demo"
                  class="bg-light pt-3"
                >
                  <VueFileAgent
                    ref="portriatRef"
                    class="profile-pic-upload-block"
                    :multiple="false"
                    :deletable="false"
                    :meta="false"
                    :compact="true"
                    :accept="'image/*'"
                    :help-text="$t('Drag an image file here')"
                    :error-text="{ type: 'Please select an image', }"
                    @select="onSelectPortriat()"
                  >
                    <template v-slot:before-outer />
                    <template v-slot:after-inner>
                      <span class="btn btn-link btn-sm btn-block">{{ $t('Select image file') }}</span>
                    </template>
                    <template v-slot:after-outer>
                      <div>
                        <p>{{ $t('portriatLabel') }}</p>
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          :class="{ 'disabled': !portiatImage }"
                          :disabled="!portiatImage"
                          @click="uploadPortriat()"
                        >
                          <span v-if="!portiatImageUploaded">{{ $t('Upload') }}</span>
                          <b-spinner
                            v-if="portiatImageUploaded"
                            small
                          />
                          <span
                            v-if="portiatImageUploaded"
                            class="sr-only"
                          >Loading...</span>
                          <span v-if="portiatImageUploaded">{{ $t('uploadingSpinner') }}</span>
                        </button>
                        <button
                          type="button"
                          class="btn w-100 mt-1"
                          :class="[portiatImage ? 'btn-danger' : 'btn-secondary']"
                          @click="removePortriatPic()"
                        >{{
                          $t('Remove') }}
                        </button>
                        <div class="clearfix" />
                      </div>
                    </template>
                  </VueFileAgent>
                </div>
              </b-col>
              <b-col
                md="6"
                sm="12"
                class="mb-2"
              >

                <div
                  id="profile-pic-demo"
                  class="bg-light pt-3"
                >
                  <VueFileAgent
                    ref="landScapeRef"
                    class="profile-pic-upload-block"
                    :multiple="false"
                    :deletable="false"
                    :meta="false"
                    :compact="true"
                    :accept="'image/*'"
                    :help-text="$t('Drag an image file here')"
                    :error-text="{ type: 'Please select an image', }"
                    @select="onSelectlandScape()"
                  >
                    <template v-slot:before-outer />
                    <template v-slot:after-inner>
                      <span class="btn btn-link btn-sm btn-block">{{ $t('Select image file') }}</span>
                    </template>
                    <template v-slot:after-outer>
                      <div>
                        <p>{{ $t('landScapeLable') }}</p>
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          :class="{ 'disabled': !landScapeImage }"
                          :disabled="!landScapeImage"
                          @click="uploadLandScape()"
                        ><span
                           v-if="landScapeImageUploaded !== true"
                         >{{ $t('Upload') }}</span>

                          <b-spinner
                            v-if="landScapeImageUploaded"
                            small
                          />
                          <span
                            v-if="landScapeImageUploaded"
                            class="sr-only"
                          >Loading...</span>
                          <span v-if="landScapeImageUploaded"> {{ $t('uploadingSpinner') }}</span>
                        </button>
                        <button
                          type="button"
                          class="btn w-100  mt-1"
                          :class="[landScapeImage ? 'btn-danger' : 'btn-secondary']"
                          @click="removeLandscapePic()"
                        >{{
                          $t('Remove') }}</button>
                        <div class="clearfix" />
                      </div>
                    </template>
                  </VueFileAgent>
                </div>
              </b-col>

            </b-row>
          </div>
          <b-col class="mb-2">
            <b-button
              class="mx-1"
              :ripple.400="'rgba(113, 102, 240, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="landscapeMatId === null || portriatMatId === null || portiatImage == false || landScapeImage == false"
            >
              {{ $t("UpdateLocationMats") }}
            </b-button>
            <b-button @click="locationMaterialModal = false">
              {{ $t("close") }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { normalizeUnits } from 'moment'
import useJwt from '@/auth/useJwt'
import leafletMap from '@/@core/components/leaflet-map/leafletMap.vue'
import store from '@/store'

export default {
  name: 'AddLocations',
  components: {
    leafletMap,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      landScapeImageUploaded: false,
      portiatImageUploaded: false,
      locationId: '',
      toLocationId: null,
      portiatImage: false,
      landScapeImage: false,
      locationMoveScreenModal: false,
      locationMaterialModal: false,
      landscapeMatId: null,
      portriatMatId: null,
      readyMap: null,
      genderOptions: [
        { id: 1, name: 'Both' },
        {
          id: 2,
          name: 'Male',
        },
        { id: 3, name: 'Female' },
      ],
      tableDetailsInfo: [],
      selectionMap: {
        center: [24.774265, 46.738586],
        zoom: 12,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        height: '300px',
        selectedLocation: [],
      },
      filterOn: [],
      username: null,
      modalShow2: false,
      geojson: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 5,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      activatePartners: [],
      cities: [],
      city: null,
      neighborhoods: [],
      modalShow: false,
      shownItem: {
        username: null,
        partnerIdType: null,
        locationId: '',
        titleAr: '',
        titleEn: '',
        city: null,
        neighborhood: null,
        coveredSegments: [],
        latitude: '',
        longitude: '',
        estimatedTraffic: '',
        prohibitedSubjects: [],
        defaultPrice: '',
        isPrivate: false,
        businessField: null,
        genderOption: null, // "Men", "Women"
      },
      queryFilter: {
        partnerIdType: '',
        titleAr: '',
      },
      setups: [],
      isLoading: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'pricePerHour',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      fields: [
        {
          key: 'partnerIdType',
          label: this.$t('Partner Id Type'),
          sortable: true,
        },
        {
          key: this.$t('locationTitle'),
          label: this.$t('Title'),
          sortable: true,
        },
        { key: 'Setups', label: this.$t('details'), sortable: true },
        { key: 'isPrivate', label: this.$t('locationStatus'), sortable: true },
        { key: 'actions', label: this.$t('Actions'), sortable: false },
      ],
      segment: null,
      segments: [],
      names: 'test',
      moveScreensInfo: {
        fromLocationId: null,
        toLocationId: null,
        filterLocations: [],
      },
      locationInfo: {
        username: null,
        partnerIdType: null,
        locationId: '',
        titleAr: '',
        titleEn: '',
        city: null,
        neighborhood: null,
        coveredSegments: [],
        latitude: '',
        longitude: '',
        estimatedTraffic: '',
        prohibitedSubjects: [],
        defaultPrice: '',
        isPrivate: false,
        businessField: null,
        genderOption: null, // "Men", "Women"
      },
      allLocationsData: '',
    }
  },
  computed: {
    isAdmin() {
      console.log(this.$store)
      return this.$store.state.app.userRole === 'Admin'
    },
    isRtl() {
      return store.state.appConfig.layout.isRTL
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    filteredSetups() {
      return this.applyFilter()
    },
  },
  watch: {
    queryFilter: {
      deep: true,
      handler() {
        this.applyFilter()
      },
    },
    isRtl: {
      deep: true,
      handler() {
        this.refreshFields()
      },
    },
  },
  async created() {
    this.getAllLocations()
    this.getCities()
    this.getSegments()
    this.getPartners()
    const response = await fetch(
      'https://raw.githubusercontent.com/wjdanalharthi/GeoJSON-of-Saudi-Arabia-Regions/master/data/SA_regions.json',
    )
    this.geojson = await response.json()
  },
  mounted() {
    this.queryFilter = { ...this.$route.query }
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.segment = null
      this.city = null
      this.username = null
      this.shownItem = {
        username: null,
        partnerIdType: null,
        locationId: '',
        titleAr: '',
        titleEn: '',
        city: null,
        neighborhood: null,
        coveredSegments: [],
        latitude: '',
        longitude: '',
        estimatedTraffic: '',
        prohibitedSubjects: [],
        defaultPrice: '',
        businessField: null,
        isPrivate: false,
        genderOption: null, // "Men", "Women"
      }
    })
  },
  methods: {
    onMapClick(e) {
      console.log(e)
      this.shownItem.latitude = e.latlng.lat
      this.shownItem.longitude = e.latlng.lng
      this.selectionMap.selectedLocation = [{ locationInfo: { ...this.shownItem, locationCoordinates: { latitude: e.latlng.lat, longitude: e.latlng.lng } }, locationId: this.shownItem.locationId }]
      console.log(this.shownItem)
      console.log(this.selectionMap.selectedLocation)
    },
    isLocationDeletable(location) {
      console.log(location)
      console.log(this.isAdmin)
      console.log(location.locationInfo.screens.length)
      const canBeDeleted = !this.isAdmin || location.locationInfo.screens.length > 0
      return canBeDeleted
    },
    closeMoveScreenModal() {
      console.log('closeMoveScreenModal')
      this.toLocationId = null
      this.moveScreensInfo.fromLocationId = null
      this.moveScreensInfo.toLocationId = null
      this.moveScreensInfo.filterLocations = []
      this.locationMoveScreenModal = false
    },
    selectToLocation(value) {
      console.log(this.moveScreensInfo)
      if (value !== null) {
        console.log(value)
        this.moveScreensInfo.toLocationId = value.locationId
      }
    },
    showLocationMoveScreenModal(location) {
      console.log(location)
      this.moveScreensInfo.filterLocations = this.setups.filter(
        l => l.locationId !== location.locationId,
      )
      this.moveScreensInfo.fromLocationId = location.locationId
      this.locationMoveScreenModal = true
      console.log(this.moveScreensInfo)
    },
    async doMoveLocationScreens() {
      console.log(this.moveScreensInfo)
      const data = {
        locationId: this.moveScreensInfo.fromLocationId,
        toLocationId: this.moveScreensInfo.toLocationId,
      }
      console.log(data)
      await this.$swal({
        title: this.$t('Are you sure'),
        text: this.$t('You wont be able to revert this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          useJwt
            .post('/asset/api/domain/locations/move', data)
            .then(res => {
              console.log(res)
              this.$swal({
                icon: 'success',
                title: this.$t('Success'),
                text: this.$t('Screens moved successfully'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              const { screens } = this.setups.find(setup => setup.locationInfo.locationId === this.moveScreensInfo.fromLocationId).locationInfo
              console.log(screens)
              this.setups = [...this.setups.map(setup => {
                console.log(setup)
                if (setup.locationInfo.locationId === this.moveScreensInfo.fromLocationId) {
                  return { ...setup, locationInfo: { ...setup.locationInfo, screens: [] } }
                }
                if (setup.locationInfo.locationId === this.moveScreensInfo.toLocationId) {
                  return { ...setup, locationInfo: { ...setup.locationInfo, screens: [...setup.locationInfo.screens, ...screens] } }
                }
                return setup
              })]
              this.closeMoveScreenModal()
            })
            .catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: this.$t(`Something went wrong: ${err}`),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    removePortriatPic() {
      this.$refs.portriatRef.deleteFileRecord()
      this.portiatImage = false
    },
    removeLandscapePic() {
      this.$refs.landScapeRef.deleteFileRecord()
      this.landScapeImage = false
    },
    uploadPortriat() {
      this.portiatImageUploaded = true
      console.log('onSelect', this.$refs.portriatRef.fileRecords[0].file)
      const formData = new FormData()
      formData.append('file', this.$refs.portriatRef.fileRecords[0].file)
      // this.uploaded = true
      useJwt.post(`/adv/api/domain/location/${this.locationId}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      }).then(res => {
        console.log('res', res)
        const title = this.$t('Success')
        const msg = this.$t('imageUploadedSuccessfully')
        const state = 'success'
        this.portiatImageUploaded = false
        this.portriatMatId = res.data.fileId
        this.showToast(null, title, msg, state)
      }).catch(err => {
        console.log('err', err)
        const title = this.$t('Oops')
        const msg = this.$t('Something went wrong"')
        const state = 'danger'
        this.portiatImageUploaded = false
        this.showToast(null, title, msg, state)
      }).finally(() => this.portiatImageUploaded = false)
    },
    uploadLandScape() {
      this.landScapeImageUploaded = true
      console.log('onSelect', this.$refs.landScapeRef.fileRecords[0].file)
      const formData = new FormData()
      formData.append('file', this.$refs.landScapeRef.fileRecords[0].file)
      // this.uploaded = true
      useJwt.post(`/adv/api/domain/location/${this.locationId}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      }).then(res => {
        const title = this.$t('Success')
        const msg = this.$t('imageUploadedSuccessfully')
        const state = 'success'
        this.landScapeImageUploaded = false
        this.landscapeMatId = res.data.fileId
        this.showToast(null, title, msg, state)
      }).catch(err => {
        console.log('err', err)
        const title = this.$t('Oops')
        const msg = this.$t('Something went wrong"')
        const state = 'danger'
        this.landScapeImageUploaded = false
        this.showToast(null, title, msg, state)
      }).finally(() => this.landScapeImageUploaded = false)
    },
    onSelectPortriat() {
      console.log(this.$refs.portriatRef.fileRecords[0].file)
      this.portiatImage = true
    },
    onSelectlandScape() {
      console.log(this.$refs.landScapeRef.fileRecords[0].file)
      this.landScapeImage = true
    },
    showlocationMaterial(location) {
      console.log('location', location)
      this.locationId = location.locationId
      this.locationMaterialModal = true
    },
    updateLocationStatus(location, status) {
      console.log(location, status)
      location.prohibitedSubjects = []
      useJwt.put('asset/api/domain/location', location)
        .then(res => {
          this.$swal({
            icon: 'success',
            title: this.$t('Approved'),
            text: this.$t('statusChangedSuccessfully'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.getAllLocations()
        }).catch(err => {
          this.$swal({
            icon: 'error',
            title: this.$t('Oops'),
            text: this.$t('Something went wrong'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },
    locationStatus(status) {
      return status ? this.$t('Private') : this.$t('Public')
    },
    showDetail(object) {
      console.log(object)
      this.tableDetailsInfo = [{ locationInfo: object }]
      console.log(this.tableDetailsInfo)
      this.$bvModal.show('tableDetails')
    },
    showToast(data, title, msg, state) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title || Object.keys(data)[0].toString() || '',
          icon: 'BellIcon',
          text: msg || JSON.stringify(Object.values(data)[0]) || '',
          variant: state || 'danger',
        },
      })
    },
    selectUsername(value) {
      this.shownItem.username = value.username
      this.shownItem.partnerIdType = value.partnerIdType
    },
    changeSegments(value) {
      this.shownItem.coveredSegments = []
      this.shownItem.coveredSegments.push(this.segment)
      this.shownItem.businessField = this.segment
    },
    getSegments() {
      this.isLoading = true
      useJwt
        .get('/qasset/api/public/segments')
        .then(({ data }) => {
          this.segments = data.data.segments
        })
        .catch(err => {
          console.error()
        })
        .finally(() => (this.isLoading = false))
    },
    changeNeighborhoods(value) {
      this.shownItem.neighborhood = value.id
    },
    changeCity(value) {
      this.shownItem.city = this.city.id
      this.neighborhoods = this.city.neighborhoods
    },
    backToCenter() {
      const map = this.$refs.mymap.mapObject
      this.markerLatLng = this.center
      map.setView(this.center, this.zoom)
    },
    showModal() {
      this.modalShow = true
    },
    showModal2() {
      this.modalShow2 = true
      setTimeout(() => {
        // mapObject is a property that is part of leaflet
        this.$refs.mymap.mapObject.invalidateSize()
        const map = this.$refs.mymap.mapObject
        const osm = L.TileLayer.boundaryCanvas(
          'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            boundary: this.geojson,
            attribution: this.attribution,
          },
        ).addTo(map)
      }, 100)
    },
    applyFilter() {
      let { setups } = this
      Object.keys(this.queryFilter).forEach(filterName => {
        if (
          this.queryFilter[filterName]
          && this.queryFilter[filterName] !== ''
        ) {
          if ([filterName].find(element => element === filterName)) {
            setups = setups.filter(e => e[filterName]
              .toString()
              .toLowerCase()
              .includes(this.queryFilter[filterName].toString().toLowerCase()))
          } else {
            setups = setups.filter(
              e => e[filterName] == this.queryFilter[filterName],
            )
          }
        }
      })
      this.onFiltered(setups)
      return setups
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async deleteLocation(location) {
      console.log(location)
      if (location.locationInfo.screens.length > 0) {
        this.$swal({
          title: this.$t('Warning'),
          text: this.$t('Location contains active screens, please move them before deleting the location'),
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: this.$t('OK'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
      } else {
        await this.$swal({
          title: this.$t('Are you sure'),
          text: this.$t('You wont be able to revert this'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            console.log(screen.sinageId)
            useJwt
              .delete(`/asset/api/domain/location/${location.locationId}`)
              .then(({ data }) => {
                console.log(data)
                this.$swal({
                  icon: 'success',
                  title: this.$t('Success'),
                  text: this.$t('The Location Info deleted successfully'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.setups = this.setups.filter(e => e.locationId !== location.locationId)
              })
              .catch(err => {
                console.log(err)
                err.data.data.errors.forEach(err => {
                  this.showToast(err)
                })
              })
          }
        })
      }
    },
    async submitLocationMaterial() {
      await useJwt
        .put('/adv/api/domain/adv/location/materials', {
          locationId: this.locationId,
          assets: {
            hdLandscape: this.landscapeMatId,
            hdPortrait: this.portriatMatId,
          },
        })
        .then(({ data }) => {
          this.locationMaterialModal = false
          this.landScapeImageUploaded = false
          this.portiatImageUploaded = false
          this.portiatImage = false
          this.landScapeImage = false
          this.landscapeMatId = null
          this.portriatMatId = null
          this.$swal({
            icon: 'success',
            title: this.$t('Success'),
            text: this.$t('The Location Info updated successfully'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(err => {
          err.data.data.errors.forEach(err => {
            this.showToast(err)
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async submitData() {
      this.shownItem.latitude = Number(this.shownItem.latitude)
      this.shownItem.longitude = Number(this.shownItem.longitude)
      this.shownItem.defaultPrice = Number(this.shownItem.defaultPrice)
      this.shownItem.estimatedTraffic = Number(this.shownItem.estimatedTraffic)

      await useJwt
        .post('/asset/api/domain/location', {
          ...this.shownItem,
        })
        .then(({ data }) => {
          this.modalShow = false
          this.$swal({
            icon: 'success',
            title: this.$t('Success'),
            text: this.$t('The Location Info added successfully'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.setups = [...this.setups.filter(e => e.id !== data.data.id)]
        })
        .catch(err => {
          err.data.data.errors.forEach(err => {
            this.showToast(err)
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getAllLocations() {
      this.isLoading = true
      useJwt
        .get('/qasset/api/domain/locations')
        .then(({ data }) => {
          console.log()
          this.setups = data.data.locations
          this.totalRows = this.setups.length
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getCities() {
      this.isLoading = true
      useJwt
        .get('/qasset/api/public/cities')
        .then(({ data }) => {
          this.cities = data.data.cities
          this.cities.forEach(e => {
            e.value = e.id
          })
        })
        .catch(err => {
          console.error()
        })
        .finally(() => (this.isLoading = false))
    },
    getPartners() {
      this.isLoading = true

      if (this.isAdmin) {
        useJwt
          .get('/qpartner/api/domain/partners')
          .then(({ data }) => {
            this.activatePartners = data.data.activatePartners
          })
          .catch(err => {
            console.error()
          })
          .finally(() => (this.isLoading = false))
      } else {
        this.data = JSON.parse(localStorage.getItem('userInfo'))
        this.activatePartners = [{ username: this.data.partnerInfo.data.username, partnerIdType: 'CompanyId', partnerInfo: this.data.partnerInfo }]
      }
    },
    refreshFields() {
      this.fields = [
        {
          key: 'partnerIdType',
          label: this.$t('Partner Id Type'),
          sortable: true,
        },
        {
          key: this.$t('locationTitle'),
          label: this.$t('Title'),
          sortable: true,
        },
        { key: 'Setups', label: this.$t('details'), sortable: true },
        { key: 'isPrivate', label: this.$t('locationStatus'), sortable: true },
        { key: 'isPrivate"', label: this.$t('locationMaterial'), sortable: false },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
#profile-pic-demo .drop-help-text {
  display: none;
}

#profile-pic-demo .is-drag-over .drop-help-text {
  display: block;
}

#profile-pic-demo .profile-pic-upload-block {
  border: 2px dashed transparent;
  padding: 20px;
  padding-top: 0;
}

#profile-pic-demo .is-drag-over.profile-pic-upload-block {
  border-color: #AAA;
}

#profile-pic-demo .vue-file-agent {
  width: 180px;
  float: left;
  margin: 0 15px 5px 0;
  border: 0;
  box-shadow: none;
}

.ml-map {
  margin-inline-start: 2px;
  cursor: pointer;
}

.fa-map-marker:before {
  content: "\f041";
}

.leaflet-control-locate a.leaflet-bar-part div {
  background-position: left 8px top 8px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}

.leaflet-control-locate a.leaflet-bar-part div.loading {
  background-image: url(https://rawcdn.githack.com/gravitystorm/openstreetmap-carto/eae09090b64c361be3ff7bfd1975be7638a6b36c/symbols/man_made/tower_lattice_communication.svg);
}

.leaflet-control-locate a.leaflet-bar-part div.locate {
  background-image: url(https://rawcdn.githack.com/gravitystorm/openstreetmap-carto/eae09090b64c361be3ff7bfd1975be7638a6b36c/symbols/man_made/tower_lattice.svg);
}

</style>
